/* Sidebar.css */

.sidebar {
    width: 200px;
    height: 100vh;
    background-color: #e0e0e0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }
  
  .main-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: #f0f0f0;
    border: 0.1px solid rgba(85, 85, 85, 0.3);
    border-radius: 3px;
    margin-top: -1px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .main-button.active {
    color: white;
    background-color: #555555;
  }
  
  .sub-button {
    display: block;
    width: 90%;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: #d1ecf1;
    border: 0.1px solid rgba(0, 123, 255, 0.3);
    border-radius: 2px;
    margin-top: 0px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .sub-button.active {
    color: white;
    background-color: #0033ffb0;
  }
  
  .logout-button {
    background-color: #d63737;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: -13px;
  }
  
  .submenu {
    margin-left: -10px;
    margin-right: -20px;
    margin-top: 0px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .submenu.open {
    max-height: 500px; /* 足够容纳子按钮的高度 */
    transition: max-height 0.5s ease-in;
  }
  
  .icon {
    margin-left: 10px;
  }
  